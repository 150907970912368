import dayjs from 'dayjs'

import { HospitalizationType } from '@/store/modules/stay/ssr/types'

export const statusLabels = {
  new: 'Nouveau',
  review: 'À revoir',
  unvalidated: 'Non-validé',
  validated: 'Validé',
}

export const HospitalizationTypeIcon = {
  [HospitalizationType.COMPLETE]: '●',
  [HospitalizationType.PARTIAL_DAY]: '◔',
  [HospitalizationType.PARTIAL_NIGHT]: '◕',
  [HospitalizationType.AMBULATORY]: '○',
}

export const HospitalizationTypeLabel = {
  [HospitalizationType.COMPLETE]: 'HC',
  [HospitalizationType.PARTIAL_DAY]: 'HTP (jour)',
  [HospitalizationType.PARTIAL_NIGHT]: 'HTP (nuit)',
  [HospitalizationType.AMBULATORY]: 'AMB',
}

export const HospitalizationTypeFullName = {
  [HospitalizationType.COMPLETE]: 'complète',
  [HospitalizationType.PARTIAL_DAY]: 'partielle (jour)',
  [HospitalizationType.PARTIAL_NIGHT]: 'partielle (nuit)',
  [HospitalizationType.AMBULATORY]: 'ambulatoire',
}

export const roleLabels = {
  ROLE_SANCARE_DEV: 'Développeur Sancare',
  ROLE_DIM: 'DIM',
  ROLE_TIM_MANAGER: 'Cadre TIM',
  ROLE_TIM: 'TIM',
  ROLE_DIM_IT: 'Informaticien DIM',
  ROLE_DAF: 'DAF',
  ROLE_DSI: 'DSI',
  ROLE_SANCARE_EDITOR: 'Editeur Sancare',
  ROLE_SANCARE_VIEWER: 'Utilisateur Sancare',
  ROLE_SANCARE_ADMIN: 'Admin Sancare',
  ROLE_DATA_PROVIDER: 'Data Provider',
}

export const FPP_DIAGNOSIS_EXPIRATION_DATE = dayjs('2023-02-27')
