import { actions } from './actions'
import { mutations } from './mutations'
import { state } from './state'

// todo: move in frontend commons https://app.clickup.com/t/86c1cygnm
export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
